import { Box, Button, LinearProgress, Typography } from "@mui/material"
import { DataGrid, GridColDef, GridRowSelectionModel, GridValueGetterParams } from "@mui/x-data-grid"
import { isBefore } from "date-fns"
import Error from "next/error"
import { FC, useContext, useEffect, useMemo, useState } from "react"
import { BiDownload, BiEdit, BiFile, BiImage, BiPlus, BiSolidFilePdf, BiTrash } from "react-icons/bi"
import { useQuery } from "urql"
import { File as CrewViewFile } from "../../../graphql/generated/client-types-and-hooks"
import { graphql, makeFragmentData } from "../../../graphql/generated/gql"
import { FRAGMENTS } from "../../../graphql/queries/fragments.queries"
import { DocumentModelType } from "../../../helpers/files/fileUpload"
import { colors } from "../../../helpers/styles/colors"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { getFileType } from "../../../lib/fileTypeHelpers"
import { CheckedPermission } from "../../../types/Permission"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import Pill from "../../Pill"
import { QuickMenuDotsHorizontal } from "../../QuickMenu/QuickMenuDotsHorizontal"
import { QuickMenuMui } from "../../QuickMenuMui"
import { RenderIf } from "../../RenderIf"
import { SearchBar } from "../../deprecated/SearchBar"
import { DocumentPreviewDrawer } from "../Drawer/components/DocumentPreviewDrawer"
import { DrawerContext } from "../Drawer/providers/DrawerProvider"
import { AddOrEditDocumentModal, DeleteDocumentModal, PreviewDocumentModal } from "./DocumentModals"
import { downloadByDialog, downloadByLink, supportsFilePickerApi } from "./documents.utils"
import { EmptyStateBlock } from "../../Table/EmptyStateBlock"

const { gray } = colors

const queries = {
  asset: graphql(`
    query AssetDocumentsTable($assetId: String!) {
      asset(id: $assetId) {
        id
        files {
          ...FileBase
        }
      }
    }
  `),
  project: graphql(`
    query ProjectDocumentsTable($projectId: String!) {
      project(id: $projectId) {
        id
        documents {
          ...FileBase
        }
      }
    }
  `),
  user: graphql(`
    query UserDocumentsTable($userId: String!) {
      user(id: $userId) {
        id
        myFiles {
          ...FileBase
        }
      }
    }
  `),
  vendor: graphql(`
    query VendorDocumentsTable($vendorId: String!) {
      vendor(id: $vendorId) {
        id
        files {
          ...FileBase
        }
      }
    }
  `),
}

type DocumentsTableProps = {
  modelId: string
  requiredPermissions: CheckedPermission[]
  title: string
  type: DocumentModelType
}

export const DocumentsTable: FC<DocumentsTableProps> = ({ requiredPermissions, title, type, modelId }) => {
  const [{ data: assetDocumentsData }, refetchAssetDocuments] = useQuery({
    query: queries.asset,
    variables: { assetId: modelId },
    pause: type !== DocumentModelType.Asset,
  })
  const [{ data: projectDocumentsData }, refetchProjectDocuments] = useQuery({
    query: queries.project,
    variables: { projectId: modelId },
    pause: type !== DocumentModelType.Project,
  })
  const [{ data: userDocumentsData }, refetchUserDocuments] = useQuery({
    query: queries.user,
    variables: { userId: modelId },
    pause: type !== DocumentModelType.User,
  })
  const [{ data: vendorDocumentsData }, refetchVendorDocuments] = useQuery({
    query: queries.vendor,
    variables: { vendorId: modelId },
    pause: type !== DocumentModelType.Vendor,
  })

  const [searchText, setSearchText] = useState<string>("")

  const [documentToPreview, setDocumentToPreview] = useState<CrewViewFile>()
  const [documentToDelete, setDocumentToDelete] = useState<CrewViewFile>()
  const [documentToEdit, setDocumentToEdit] = useState<CrewViewFile>()

  const previewDocumentModalProps = useModalProps("Preview Document")
  const deleteDocumentModalProps = useModalProps("Delete Document")
  const createOrEditDocumentModalProps = useModalProps("Document")

  const documents = useMemo<CrewViewFile[]>(() => {
    if (assetDocumentsData?.asset?.files)
      return assetDocumentsData.asset.files.map((file) => makeFragmentData(file, FRAGMENTS.FILE.BASIC))
    if (projectDocumentsData?.project?.documents)
      return projectDocumentsData.project.documents.map((file) => makeFragmentData(file, FRAGMENTS.FILE.BASIC))
    if (userDocumentsData?.user?.myFiles)
      return userDocumentsData.user.myFiles.map((file) => makeFragmentData(file, FRAGMENTS.FILE.BASIC))
    if (vendorDocumentsData?.vendor?.files)
      return vendorDocumentsData.vendor.files.map((file) => makeFragmentData(file, FRAGMENTS.FILE.BASIC))

    return []
  }, [
    assetDocumentsData?.asset?.files,
    projectDocumentsData?.project?.documents,
    userDocumentsData?.user?.myFiles,
    vendorDocumentsData?.vendor?.files,
  ])

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])
  const [filteredRows, setFilteredRows] = useState<CrewViewFile[]>([])

  useEffect(() => {
    if (!documents) return

    if (searchText && documents) {
      const filtered = documents.filter((doc) => doc.fileName.toLowerCase().includes(searchText.toLowerCase())) || []

      setFilteredRows(filtered)
    } else {
      setFilteredRows(documents)
    }
  }, [searchText, documents])

  const gridColumns = useMemo(() => {
    const onPreview = (document: CrewViewFile) => {
      setDocumentToPreview(document)
      previewDocumentModalProps.handleOpenModal()
    }

    const onEdit = (document: CrewViewFile) => {
      setDocumentToEdit(document)
      createOrEditDocumentModalProps.handleOpenModal()
    }

    const onDownload = async (document: CrewViewFile) => {
      if (supportsFilePickerApi()) {
        downloadByDialog(document)
      } else {
        downloadByLink(document)
      }
    }

    const onDelete = (document: CrewViewFile) => {
      setDocumentToDelete(document)
      deleteDocumentModalProps.handleOpenModal()
    }

    return getGridColumns(onPreview, onEdit, onDownload, onDelete)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refetchDocuments = () => {
    if (assetDocumentsData) refetchAssetDocuments()
    if (projectDocumentsData) refetchProjectDocuments()
    if (userDocumentsData) refetchUserDocuments()
    if (vendorDocumentsData) refetchVendorDocuments()

    return () => {}
  }

  return (
    <RenderIf permissionsInclude={requiredPermissions} fallbackComponent={<Error statusCode={404} />}>
      <Box display="grid" gap={4}>
        <Box>
          {title && (
            <Typography variant="h3" paddingBottom={2}>
              {title}
            </Typography>
          )}
          <div className="flex flex-row justify-between">
            <SearchBar onChange={setSearchText} onCancel={() => setSearchText("")} />
            <Button
              variant="contained"
              color="secondary"
              endIcon={<BiPlus />}
              disableElevation
              className="text-left text-gray-800 bg-gray-100 rounded hover:bg-gray-200 hover:rounded transition-all cursor-pointer px-4"
              onClick={createOrEditDocumentModalProps.handleOpenModal}
            >
              Add document
            </Button>
          </div>
        </Box>

        <Box sx={{ height: "auto", width: "100%" }}>
          <DataGrid
            autoHeight
            rows={filteredRows}
            columns={gridColumns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            disableRowSelectionOnClick
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel)
            }}
            rowSelectionModel={rowSelectionModel}
            slots={{
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => <EmptyStateBlock label="No documents" />,
            }}
          />
        </Box>
      </Box>

      <PreviewDocumentModal {...previewDocumentModalProps} document={documentToPreview} />
      <DeleteDocumentModal
        {...deleteDocumentModalProps}
        document={documentToDelete}
        refetchDocuments={refetchDocuments}
      />
      <AddOrEditDocumentModal
        {...createOrEditDocumentModalProps}
        handleCloseModal={() => {
          createOrEditDocumentModalProps.handleCloseModal()
          setDocumentToEdit(undefined)
        }}
        documentToUpdate={documentToEdit}
        modelId={modelId}
        modelType={type}
        refetchDocuments={refetchDocuments}
      />
    </RenderIf>
  )
}

const getGridColumns = (
  onPreview: (document: CrewViewFile) => void,
  onEdit: (data: CrewViewFile) => void,
  onDownload: (document: CrewViewFile) => void,
  onDelete: (document: CrewViewFile) => void
) => {
  const columns: GridColDef[] = [
    {
      field: "imageUrl",
      headerName: "",
      width: 60,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => <DocumentIcon data={row} onPreview={onPreview} />,
    },
    {
      field: "fileName",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => <DocumentName data={params.row} onPreview={onPreview} />,
    },
    {
      field: "createdAt",
      headerName: "Uploaded",
      width: 120,
      type: "date",
      valueGetter: (params: GridValueGetterParams) => params.row.createdAt,
    },
    {
      field: "expiresAt",
      headerName: "Exp. Date",
      width: 120,
      type: "string",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.expiresAt ? params.row.expiresAt.toLocaleDateString() : "None",
      renderCell: (params) => (
        <Typography color={params.formattedValue === "None" ? gray[400] : ""}>{params.formattedValue}</Typography>
      ),
    },
    {
      field: "contentType",
      headerName: "Type",
      width: 75,
      valueGetter: (params: GridValueGetterParams) => getFileType(params.row.contentType),
      renderCell: (params) => <Typography>{getFileType(params.row.contentType)}</Typography>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      sortable: false,
      valueGetter: ({ row: { expiresAt } }: GridValueGetterParams) => {
        return isBefore(expiresAt, new Date().setHours(0, 0, 0, 0)) ? "expired" : "active"
      },
      renderCell: (params) => {
        const status = params.formattedValue
        return (
          <>
            {status === "active" && <Pill color="blue">Active</Pill>}
            {status !== "active" && <Pill color="orange">Expired</Pill>}
          </>
        )
      },
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      editable: false,
      width: 80,
      renderCell: ({ row }) => {
        return (
          <QuickMenuMui
            items={[
              [
                {
                  value: "Edit",
                  Icon: BiEdit,
                  onClick: () => onEdit(row),
                },
                {
                  value: "Download",
                  Icon: BiDownload,
                  onClick: () => onDownload(row),
                },
              ],
              [
                {
                  value: "Delete",
                  onClick: () => onDelete(row),
                  Icon: BiTrash,
                  color: "red",
                },
              ],
            ]}
            buttonShape="round"
          >
            <QuickMenuDotsHorizontal />
          </QuickMenuMui>
        )
      },
    },
  ]

  return columns
}

const DocumentIcon: FC<{
  data: CrewViewFile
  onPreview: (document: CrewViewFile) => void
  flagIsEnabled?: boolean
}> = ({ data, onPreview, flagIsEnabled = false }) => {
  const { documentUrl, fileName, contentType } = data
  const { push: pushDrawer } = useContext(DrawerContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])
  const iconStyle = "p-0 text-gray-400 w-10 h-10"
  const iconPreviewStyle = "max-w-[2.5rem] max-h-[2.5rem] ml-auto mr-auto"

  if (!documentUrl) return null

  return (
    <div className="max-w-10 max-h-10 relative cursor-pointer my-auto">
      <div
        className="absolute z-20 top-0 left-0 size-full"
        onClick={(e) => {
          e.preventDefault()
          flagIsEnabled && isMobile
            ? pushDrawer(<DocumentPreviewDrawer document={data} />, "DocumentPreview")
            : onPreview(data)
        }}
      ></div>

      {contentType.includes("pdf") ? (
        isMobile ? (
          <BiSolidFilePdf className={iconStyle} />
        ) : (
          <object data={documentUrl} className={iconPreviewStyle}>
            <BiSolidFilePdf className={iconStyle} />
          </object>
        )
      ) : contentType.includes("svg+xml") ? (
        <BiImage className={iconStyle} />
      ) : contentType.match(new RegExp(/(jpeg|gif|png|jpg|avif|bmp|svg\+xml|tiff|webp)$/)) ? (
        <img alt={fileName || "document preview"} className={iconPreviewStyle} src={documentUrl} />
      ) : (
        <BiFile className={iconStyle} />
      )}
    </div>
  )
}

const DocumentName: FC<{ data: CrewViewFile; onPreview: (document: CrewViewFile) => void }> = ({ data, onPreview }) => {
  const { push: pushDrawer } = useContext(DrawerContext)
  const { width: browserWidth } = useWindowSize()
  const isMobile = useMemo(() => (browserWidth || 0) < 768, [browserWidth])

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault()
        isMobile ? pushDrawer(<DocumentPreviewDrawer document={data} />, "DocumentPreview") : onPreview(data)
      }}
      className="hover:underline"
    >
      {data.fileName}
    </a>
  )
}
