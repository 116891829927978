import { gql } from "urql"
import { graphql } from "../generated/gql"

export const FRAGMENTS = {
  ASSET: {
    BASIC: graphql(`
      fragment AssetBase on Asset {
        id
        active
        activeChangedAt
        assetChildCount
        assetGroupId
        assignmentsCount
        assignableId
        assignedUserId
        assignableType
        billingClassificationId
        companyAssetNumber
        deletedAt
        groupQuantity
        imageUrl
        isAssetGroup
        lastInventoriedAt
        name
        organizationId
        ownershipType
        status
      }
    `),
    ADDITIONAL: gql`
      fragment AssetAdditional on Asset {
        purchaseDetails {
          price
          date
        }
        rentalAgreement {
          rate {
            daily
            weekly
            monthly
          }
          endOn
          startOn
        }
        manufacturer {
          id
          make
          name
          year
          model
        }
        vendorContact {
          name
          email
          phone
        }
      }
    `,
    INVENTORY: gql`
      fragment AssetInventory on Asset {
        inventoryRequirements {
          intervalInSeconds
          photoRequired
        }
      }
    `,
    ASSIGNED_USER: gql`
      fragment AssetAssignedUser on Asset {
        assignedUser {
          id
          firstName
          lastName
          imageUrl
          jobTitle
        }
      }
    `,
  },

  ASSET_BILLING_CLASSIFICATION: {
    BASIC: gql`
      fragment AssetBillingClassificationBase on AssetBillingClassification {
        id
        name
      }
    `,
  },

  ASSET_REPORT: {
    BASIC: gql`
      fragment AssetReportBase on AssetReport {
        id
        assetId
        reporterId
        notes
        type
      }
    `,
    DATES: gql`
      fragment AssetReportDates on AssetReport {
        createdAt
        updatedAt
      }
    `,
    INVENTORY: gql`
      fragment AssetReportInventory on AssetReport {
        inventoryReport {
          photoUrls
          note
        }
      }
    `,
    STATUS: gql`
      fragment AssetStatus on AssetReport {
        statusChange {
          active
          status
        }
      }
    `,
    REPORTER: gql`
      fragment AssetReportReporter on AssetReport {
        reporter {
          id
          firstName
          lastName
          fullName
        }
      }
    `,
  },

  CUSTOMER: {
    BASIC: gql`
      fragment CustomerBase on Customer {
        id
        name
        image
        customerId
      }
    `,
    FULL: gql`
      fragment CustomerFull on Customer {
        id
        name
        image
        imageUrl
        customerId
        phoneNumber
        phoneNumberExt
        email
        notes
        addressLine1
        addressLine2
        city
        state
        zip
      }
    `,
  },

  DELIVERABLE_UNITS: {
    BASIC: gql`
      fragment DeliverableUnitBase on DeliverableUnit {
        id
        description
        unitOfMeasure
        referenceNumber
      }
    `,
  },

  FILE: {
    BASIC: gql`
      fragment FileBase on File {
        id
        assetId
        projectId
        userId
        vendorId

        contentType
        documentUrl
        fileName
        key

        createdAt
        expiresAt
      }
    `,
  },

  ORGANIZATION: {
    BASIC: gql`
      fragment OrganizationBase on Organization {
        id
        name
        imageUrl
        createdAt
        unassignedProjectId
        timezone
        archived
        requireTimeEntryEvidence
        requireInjuryReportOnClockout
        allowTeamMembersClockInOut
        overtimeType
        notificationSettings
      }
    `,
  },

  DIVISION: {
    BASIC: gql`
      fragment DivisionBase on Division {
        id
        name
        imageUrl
        createdAt
        unassignedProjectId
        timezone
        archived
        requireTimeEntryEvidence
        requireInjuryReportOnClockout
        overtimeType
      }
    `,
  },

  PROJECT: {
    BASIC: gql`
      fragment ProjectBase on Project {
        id
        code
        name
        createdAt
        organizationId
        unassignedTaskId
        isArchived
        isDefault
        isComplete
        startDate
        endDate
        description
        imageUrl
        customerId
        contractId
      }
    `,
    ADDITIONAL: gql`
      fragment ProjectAdditional on Project {
        location {
          lat
          lng
          radius
        }
        address {
          formattedAddress
          addressComponents {
            longName
            shortName
            types
          }
        }
        locationNotes
        googlePlace
        userCount
        assetCount
        notes {
          label
          content
        }
        workersCompCode {
          id
          name
          ncciCode
        }
      }
    `,
  },

  ROLES: {
    BASIC: gql`
      fragment RoleBase on Role {
        id
        name
        order
        description
        permissions
        widgets
      }
    `,
  },

  SCHEDULE: {
    BASIC: gql`
      fragment ScheduleBase on Schedule {
        id
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }

      fragment ScheduledBreaksBase on ScheduledBreak {
        id
        breakTask {
          id
          name
          isUnpaid
          projectId
        }
        breakTaskId
        durationInMinutes
        isActive
        localizedStartTime
        name
      }
    `,
  },

  TASK: {
    BASIC: gql`
      fragment TaskBase on Task {
        id
        name
        projectId
      }
    `,
    ADDITIONAL: gql`
      fragment TaskAdditional on Task {
        createdAt
        endDate
        startDate

        archived
        description
        estimatedHours
        hasReportableUnit
        isComplete
        isDefault
        metadata {
          label
          content
        }
        workersCompCode {
          id
          name
          ncciCode
          ncciDescription
          override
          rate
        }
      }
    `,
    SUMS: gql`
      fragment TaskSums on Task {
        timeEntriesSumDurationInSeconds
        unitGoalsSumTargetQuantity
        unitGoalsSumProgress
      }
    `,
  },
  TASK_PROGRESS_EVENTS: {
    BASIC: gql`
      fragment TaskProgressEventBase on TaskProgressEvent {
        id
        reporterId
        note
        imageUrls
      }
    `,
    DATES: gql`
      fragment TaskProgressEventDates on TaskProgressEvent {
        createdAt
        updatedAt
      }
    `,
  },

  TIME: {
    BASIC: gql`
      fragment TimeEntryBase on TimeEntry {
        id
        userId
        taskId
        startAt
        endAt
        durationInSeconds
        evidence
      }
    `,
    PHOTO_URLS: gql`
      fragment TimeEntryPhotoUrls on TimeEntry {
        signInPhotoUrl
        signOutPhotoUrl
      }
    `,
  },

  UNIT_GOAL: {
    BASIC: gql`
      fragment UnitGoalBase on UnitGoal {
        id
        targetQuantity
        deliverableUnitId
      }
    `,
  },

  UNIT_GOAL_PROGRESS: {
    BASIC: gql`
      fragment UnitGoalProgressBase on UnitGoalProgress {
        id
        unitGoalId
        progress
        createdAt
        updatedAt
      }
    `,
  },

  USER: {
    BASIC: gql`
      fragment UserBase on User {
        id
        organizationId
        projectId
        taskId
        email
        phoneNumber
        phoneNumberExt
        imageUrl
        firstName
        lastName
        jobTitle
        archived
      }
    `,
    SIMPLE: gql`
      fragment UserSimple on User {
        id
        projectId
        taskId
        firstName
        lastName
        jobTitle
        archived
      }
    `,
  },

  USER_BILLING_CLASSIFICATION: {
    BASIC: gql`
      fragment UserBillingClassificationBase on UserBillingClassification {
        id
        name
      }
    `,
  },

  Vendor: {
    BASIC: gql`
      fragment VendorBase on Vendor {
        id
        name
        image
        vendorId
      }
    `,
    FULL: gql`
      fragment VendorFull on Vendor {
        id
        name
        image
        imageUrl
        vendorId
        phoneNumber
        phoneNumberExt
        email
        notes
        addressLine1
        addressLine2
        city
        state
        zip
      }
    `,
  },

  VendorContact: {
    BASIC: gql`
      fragment VendorContactBase on VendorContact {
        id
        firstName
        lastName
        role
        image
        vendorId
        email
        phoneNumber
        phoneNumberExt
      }
    `,
  },
}
