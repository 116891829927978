import { gql } from "urql"

export const UPDATE_ONE_FILE = gql`
  mutation UpdateOneFile($id: String!, $fileName: String, $expiresAt: DateTime) {
    updateOneFile(id: $id, fileName: $fileName, expiresAt: $expiresAt) {
      id
      fileName
      expiresAt
    }
  }
`

export const DELETE_ONE_FILE = gql`
  mutation DeleteOneFile($id: String!) {
    deleteOneFile(id: $id) {
      id
    }
  }
`
