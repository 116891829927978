import { File as CrewViewFile } from "../../../graphql/generated/client-types-and-hooks"
import { getFileType } from "../../../lib/fileTypeHelpers"
import { PickPlus } from "../../../types/helpers"

export const supportsFilePickerApi = () => {
  return (
    "showSaveFilePicker" in window &&
    (() => {
      try {
        return window.self === window.top
      } catch {
        return false
      }
    })()
  )
}

declare global {
  interface Window {
    showSaveFilePicker(options: { suggestedName: string }): Promise<FileSystemFileHandle>
  }
}

export const downloadByDialog = async (
  document: PickPlus<CrewViewFile, "id" | "fileName" | "documentUrl" | "contentType" | "createdAt">
) => {
  try {
    // browser availability: https://developer.mozilla.org/en-US/docs/Web/API/Window/showSaveFilePicker#browser_compatibility
    const handle = await window.showSaveFilePicker({
      suggestedName: `${document.fileName}.${getFileType(document.contentType)}`,
    })

    const blob = await (await fetch(document.documentUrl!)).blob()

    const file = new File([blob], document.fileName, {
      lastModified: new Date(document.createdAt).getTime(),
      type: document.contentType,
    })

    const writable = await handle.createWritable()
    await writable.write(file)
    await writable.close()
  } catch (err) {
    if (err instanceof DOMException) {
      console.error(err.name, err.message)

      if (err.name !== "AbortError") {
        downloadByLink(document)
      }
    }
  }
}

export const downloadByLink = (
  document: PickPlus<CrewViewFile, "id" | "fileName" | "documentUrl" | "contentType" | "createdAt">
) => {
  const a = window.document.createElement("a")

  a.href = document.documentUrl!
  a.download = document.fileName
  a.style.display = "none"
  a.target = "_blank"

  window.document.body.append(a)

  a.click()

  setTimeout(() => {
    a.remove()
  }, 1000)
}
