import { Button } from "@mui/material"
import { FC, useContext } from "react"
import { File as CrewViewFile } from "../../../../graphql/generated/client-types-and-hooks"
import { PickPlus } from "../../../../types/helpers"
import { ImageRegExp, ObjectRegExp } from "../../Documents/DocumentModals"
import { SingleDrawerContext } from "./Elements/Drawer"
import { DrawerBody } from "./Elements/DrawerBody"
import { DrawerHeader } from "./Elements/DrawerHeader"

const FallbackUI: FC<{ document: PickPlus<CrewViewFile, "documentUrl"> }> = ({ document }) => {
  const { handleClose } = useContext(SingleDrawerContext)

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="p-10 flex flex-col ">
        <p className="text-xl flex-col">
          The document preview for this document type is unavailable. Would you like to download instead?
        </p>
      </div>
      <div className="rounded-b-xl flex gap-x-2.5 justify-end border-t-2 px-4 py-3.5 leading-none">
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        {document?.documentUrl && (
          <Button color="primary" variant="contained" href={document.documentUrl}>
            Download
          </Button>
        )}
      </div>
    </div>
  )
}

export const DocumentPreviewDrawer: FC<{
  document: PickPlus<CrewViewFile, "id" | "fileName" | "documentUrl" | "contentType"> | undefined
}> = ({ document }) => {
  const isDownloadDocument =
    document && !(ImageRegExp.test(document?.contentType) || ObjectRegExp.test(document?.contentType))

  if (!document || !document.documentUrl) return null

  return (
    <>
      <DrawerHeader href={document.documentUrl} />
      <DrawerBody className="h-full">
        {!!document.contentType && ImageRegExp.test(document.contentType) && (
          <img
            alt={document.fileName || "document preview"}
            className="mx-auto object-contain"
            src={document.documentUrl}
          />
        )}
        {!!document.contentType && ObjectRegExp.test(document.contentType) && (
          <object data={document.documentUrl} className="size-full border-none object-contain">
            <FallbackUI document={document} />
          </object>
        )}
        {isDownloadDocument && <FallbackUI document={document} />}
      </DrawerBody>
    </>
  )
}
