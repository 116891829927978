import { Box, Button, FormControl, Grid, IconButton, Modal, Tooltip, Typography } from "@mui/material"
import { Form, Formik, FormikHelpers } from "formik"
import { FC, createContext, useEffect, useState } from "react"
import { BiCloudDownload, BiExpand, BiX } from "react-icons/bi"
import Carousel from "react-material-ui-carousel"
import { useMutation } from "urql"
import * as Yup from "yup"
import { File as CrewViewFile } from "../../../graphql/generated/client-types-and-hooks"
import { DELETE_ONE_FILE, UPDATE_ONE_FILE } from "../../../graphql/queries/file.queries"
import { DocumentModelType, FileUpload, documentUpload } from "../../../helpers/files/fileUpload"
import { warnOnExitStoreActions } from "../../../stores/warnOnExit"
import { PickPlus } from "../../../types/helpers"
import { DocumentUpload } from "../../FileApi/DocumentUpload"
import { DatePicker } from "../../Formik/DatePicker"
import { TextField } from "../../Formik/TextField"
import { ModalBody } from "../../Modals/components/Elements/ModalBody"
import { MuiModal } from "../../Modals/components/Elements/MuiModal"
import { ModalProps } from "../../Modals/hooks/useModalProps"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { P } from "../../deprecated"
import { GroupedObject } from "../Summary/helpers/groupByDate"
import { downloadByDialog, downloadByLink, supportsFilePickerApi } from "./documents.utils"

export const ImageRegExp = new RegExp(/(jpeg|gif|png|jpg|svg\+xml|webp)$/)
export const ObjectRegExp = new RegExp(/(pdf|mp4|ogg)$/)

type ContextProps = {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

const ModalLoadingContext = createContext<ContextProps>({} as ContextProps)

export const PreviewDocumentModal: FC<
  {
    document?: PickPlus<CrewViewFile, "id" | "fileName" | "documentUrl" | "contentType" | "createdAt">
    images?: GroupedObject[]
    initialSlideIndex?: number
  } & ModalProps
> = ({ document, handleCloseModal, isOpen, images, initialSlideIndex }) => {
  const [isLoading, setIsLoading] = useState(false)
  const isDownloadDocument =
    document && !(ImageRegExp.test(document.contentType) || ObjectRegExp.test(document.contentType)) && !images

  if (!document?.documentUrl) return null

  const onDownload = async () => {
    if (!document) return

    if (supportsFilePickerApi()) {
      downloadByDialog(document)
    } else {
      downloadByLink(document)
    }
  }

  return (
    <Modal open={isOpen} onClose={isLoading ? () => {} : handleCloseModal}>
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-t-xl rounded-b-2xl bg-black">
        <ModalLoadingContext.Provider value={{ isLoading, setIsLoading }}>
          <ModalHeader
            images={images}
            document={document}
            handleCloseModal={handleCloseModal}
            isDownloadDocument={isDownloadDocument}
          />
          <main className="max-w-[90vw] max-h-[90vh] h-fit bg-none rounded-b-xl overflow-hidden">
            {/* Model content for Documents Tab */}
            {document && (
              <DocumentViewer
                contentType={document.contentType}
                documentUrl={document.documentUrl}
                fileName={document.fileName}
              />
            )}

            {/* Modal content for Image carousel */}
            {images && images.length > 0 && (
              <ImageCarouselViewer images={images} initialSlideIndex={initialSlideIndex} />
            )}

            {isDownloadDocument && (
              <>
                <div className="p-10 flex flex-col">
                  <P className="flex-col text-white">
                    The document preview for this document type is unavailable. Would you like to download instead?
                  </P>
                  <Box>
                    <Button endIcon={<BiCloudDownload />} variant="contained" onClick={onDownload}>
                      Download
                    </Button>
                  </Box>
                </div>
              </>
            )}
          </main>
        </ModalLoadingContext.Provider>
      </div>
    </Modal>
  )
}

export const DeleteDocumentModal: FC<
  {
    document?: PickPlus<CrewViewFile, "id" | "fileName" | "documentUrl" | "contentType" | "createdAt">
    refetchDocuments: () => void
  } & PickPlus<ModalProps, "handleCloseModal" | "isOpen">
> = ({ isOpen, handleCloseModal, document, refetchDocuments }) => {
  const [{ fetching }, deleteDocument] = useMutation(DELETE_ONE_FILE)

  const onDelete = async () => {
    const result = await deleteDocument({
      id: document?.id,
    })

    result.error ? errorSnack("There was a problem deleting the document") : successSnack("Document deleted")
    handleCloseModal()
    refetchDocuments()
  }

  if (!document) return null

  return (
    <MuiModal
      contentLabel="Delete document"
      isOpen={isOpen}
      handleCloseModal={handleCloseModal}
      showActions={true}
      isLoading={fetching}
      submitButtonColor="error"
      submitButtonText="Delete"
      submitForm={onDelete}
      variant="small"
    >
      <ModalBody>
        <Typography>
          Are you sure you want to delete the document <span className="font-semibold">{document?.fileName}?</span>
        </Typography>
      </ModalBody>
    </MuiModal>
  )
}

const ModalHeader: FC<
  {
    images: GroupedObject[] | undefined
    document: PickPlus<CrewViewFile, "id" | "fileName" | "documentUrl" | "contentType" | "createdAt">
    isDownloadDocument: boolean | undefined
  } & PickPlus<ModalProps, "handleCloseModal">
> = ({ images, document, handleCloseModal, isDownloadDocument }) => {
  const iconSize = "w-6 h-6"
  return (
    <header className="w-full h-16 px-5 flex items-center  rounded-t-xl bg-white justify-between text-xl md:text-2xl font-medium truncate">
      <Typography variant="h3" className="truncate">
        {isDownloadDocument ? "Download Document" : images ? "Image Preview" : "Document Preview"}
      </Typography>
      <div>
        <Tooltip title="Open in new tab" arrow>
          <IconButton
            onClick={() => {
              document?.documentUrl && window.open(document.documentUrl)
            }}
          >
            <BiExpand className={iconSize} />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleCloseModal}>
          <BiX className={iconSize} />
        </IconButton>
      </div>
    </header>
  )
}

const DocumentViewer: FC<PickPlus<CrewViewFile, "documentUrl" | "fileName" | "contentType">> = ({
  contentType,
  documentUrl,
  fileName,
}) => {
  if (!documentUrl || !contentType) return null

  const isImage = ImageRegExp.test(contentType)
  const isObject = ObjectRegExp.test(contentType)

  if (!isImage && !isObject) return null

  return (
    <div className="rounded-b-xl min-h-[40vh] min-w-[40vw] flex items-center justify-center">
      {isImage ? (
        <img alt={fileName || "document preview"} className="object-contain size-full" src={documentUrl} />
      ) : (
        <object
          data={documentUrl}
          name={fileName}
          className="w-full min-w-[80vw] border-none object-contain min-h-[80vh]"
        />
      )}
    </div>
  )
}

const ImageCarouselViewer: FC<{ images?: GroupedObject[]; initialSlideIndex?: number }> = ({
  images,
  initialSlideIndex,
}) => {
  // State variable to track whether the carousel is ready to display all images
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    setIsReady(false)

    /* Set a timeout to update isReady after a half-second delay, allowing the carousel to initially render with the correct image visible,while hiding the others temporarily to prevent flicker during transition. */
    const timeout = setTimeout(() => {
      setIsReady(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [initialSlideIndex])

  return (
    <main className="w-[90vw] max-w-7xl">
      <Carousel
        autoPlay={false}
        animation="slide"
        swipe={false}
        navButtonsAlwaysVisible
        index={initialSlideIndex}
        strictIndexing
        indicatorContainerProps={{
          style: {
            position: "fixed",
          },
        }}
      >
        {images &&
          images.map((img, i) => (
            <div
              key={i}
              className={`flex justify-center items-center h-[60vh] ${
                !isReady && i !== initialSlideIndex ? "invisible" : ""
              }`}
            >
              <img
                src={img.documentUrl}
                alt={`Image ${i} uploaded by ${img.reporterName} at ${img.uploaded}`}
                loading="lazy"
                className="object-contain max-h-[60vh] max-w-[60vw]"
              />
            </div>
          ))}
      </Carousel>
    </main>
  )
}

type AddOrEditDocumentFormValues = {
  fileName: string
  expiresAt: Date | undefined
}

export const AddOrEditDocumentModal: FC<
  {
    documentToUpdate?: CrewViewFile
    modelId: string
    modelType: DocumentModelType
    refetchDocuments: () => void
  } & PickPlus<ModalProps, "handleCloseModal" | "isOpen">
> = ({ documentToUpdate, modelId, modelType, isOpen, handleCloseModal, refetchDocuments }) => {
  const { setHasUnsavedChanges, confirmExit } = warnOnExitStoreActions
  const [_isDirty, setIsDirty] = useState(false)

  const [selectedFileForUpload, setSelectedFileForUpload] = useState<FileUpload>()

  const fileSelected = (file: FileUpload) => {
    setSelectedFileForUpload(file)
  }

  const [_, updateOneDocument] = useMutation(UPDATE_ONE_FILE)

  const handleSubmit = async (
    values: AddOrEditDocumentFormValues,
    { resetForm }: FormikHelpers<AddOrEditDocumentFormValues>
  ) => {
    if (!selectedFileForUpload && !documentToUpdate) {
      errorSnack("You must select a file to upload")
      return
    }

    if (documentToUpdate) {
      const result = await updateOneDocument({
        id: documentToUpdate.id,
        fileName: values?.fileName || "",
        expiresAt: values.expiresAt,
      })

      if (result.error) {
        errorSnack("There was a problem updating the document")
        console.error(result.error)

        return
      }
      setHasUnsavedChanges(false)
      successSnack("Document updated")
      handleCloseModal()
      resetForm()
      refetchDocuments()
    }

    if (selectedFileForUpload) {
      const uploadedDocument = await documentUpload(
        modelType,
        modelId,
        `${values.fileName}`,
        selectedFileForUpload.blob.type,
        selectedFileForUpload.blob,
        values?.expiresAt
      )

      if (uploadedDocument?.fileId) {
        successSnack("document successfully uploaded")
        handleCloseModal()
        resetForm()
        refetchDocuments()
        setSelectedFileForUpload(undefined)
      }

      return
    }
  }

  return (
    <Formik
      enableReinitialize
      initialTouched={{ fileName: true }}
      validationSchema={Yup.object().shape({
        fileName: Yup.string()
          .trim()
          .required("Required")
          .label("File name")
          .test("validData", "Name required", (name) => !!name?.length),
        expiresAt: Yup.date().nullable().notRequired().default(undefined),
      })}
      initialValues={{
        fileName: documentToUpdate?.fileName ?? "",
        expiresAt: documentToUpdate?.expiresAt || undefined,
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, submitForm, setFieldValue, values, dirty }) => {
        setTimeout(
          () =>
            setIsDirty((previous) => {
              if (previous !== dirty) {
                setHasUnsavedChanges(dirty)
                return dirty
              }
              return previous
            }),
          0
        )

        const onFileSelect = (fileName: string, file: File, blob: Blob) => {
          fileSelected({ file, blob })
          if (!values.fileName) setFieldValue("fileName", fileName)
        }

        return (
          <MuiModal
            contentLabel={documentToUpdate ? "Update document" : "Add document"}
            isOpen={isOpen}
            handleCloseModal={() => {
              confirmExit(() => {
                handleCloseModal()
              })
            }}
            showActions={true}
            submitButtonText={documentToUpdate ? "Update" : "Add"}
            submitForm={submitForm}
            isLoading={isSubmitting}
            hasUnsavedChanges={dirty}
          >
            <Form>
              <div className="ml-[-5px]">
                {!documentToUpdate && <DocumentUpload className="grow" onFileSelect={onFileSelect} />}
              </div>
              <Grid container spacing={3} columnSpacing={2} alignContent="start">
                <Grid item xs={12} marginTop={3} className="ml-[-6px]">
                  <TextField
                    required
                    name="fileName"
                    label="Document Name"
                    disabled={isSubmitting}
                    className="w-full"
                  />
                  <FormControl fullWidth>
                    <DatePicker
                      name="expiresAt"
                      label="Expiration Date"
                      format="MM/dd/yyyy"
                      disabled={isSubmitting}
                      className="w-full"
                      onError={(error) => console.error("error: ", error)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </MuiModal>
        )
      }}
    </Formik>
  )
}
